import store from './index'

const adherentsFilters = {
  namespaced: true,
  state: {
    search: {
      name: null,
      email: null,
      created_at: null,
      bon_id: null,
      date_assignation: null,
      insurer: null,
      validation: null,
      id: null,
    },
    options: {
      sortBy: ['created_at'],
      sortDesc: [true],
    },
    page: 1,
    per_page: 25,
  },
  getters: {
    getSearch: state => state.search,
    getOrder: state => state.order,
    getPage: state => state.page,
    getPerPage: state => state.per_page,
  },
  mutations: {
    SET_SEARCH(state, { prop, value }) {
      state.search[prop] = value
    },
    RESET_SEARCH(state) {
      Object.assign(state.search,
        {
          name: null,
          email: null,
          created_at: null,
          bon_id: null,
          date_assignation: null,
          insurer: null,
          validation: null,
          id: null,
        })
    },
    SET_OPTIONS(state, { prop, value }) {
      state.options[prop] = value
    },
    SET_PAGE(state, value) {
      state.page = value
    },
    SET_PER_PAGE(state, value) {
      state.per_page = value
    },
  },
}

store.registerModule('adherentsFilters', adherentsFilters)

export default store
