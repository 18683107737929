<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="me-3">
          Liste des adhérents
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn-toggle
              v-model="validation"
              color="deep-purple accent-3"
              group
              dense
              tile
              borderless
              elevation="0"
            >
              <v-btn
                value="1"
              >
                <v-icon
                  color="secondary"
                  left
                >
                  {{ mdiHelpCircleOutline }}
                </v-icon>
                <span class="hidden-sm-and-down">Non validés</span>
              </v-btn>

              <v-btn
                value="2"
              >
                <v-icon
                  color="warning"
                  left
                >
                  {{ mdiProgressPencil }}
                </v-icon>
                <span class="hidden-sm-and-down">En attente</span>
              </v-btn>

              <v-btn
                value="3"
              >
                <v-icon
                  color="info"
                  left
                >
                  {{ mdiCheckCircleOutline }}
                </v-icon>
                <span class="hidden-sm-and-down">Vérifiés</span>
              </v-btn>

              <v-btn
                value="5"
              >
                <v-icon
                  color="success"
                  left
                >
                  {{ mdiCheckDecagramOutline }}
                </v-icon>
                <span class="hidden-sm-and-down">Validés</span>
              </v-btn>

              <v-btn
                value="4"
              >
                <v-icon
                  color="error"
                  left
                >
                  {{ mdiAlertCircleOutline }}
                </v-icon>
                <span class="hidden-sm-and-down">Refusés</span>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div
              v-if="role === 'superAdmin'"
              class="d-flex justify-end"
            >
              <v-btn
                color="primary"
                class="me-3"
                :loading="downloading.excelOld"
                :disabled="downloading.excel"
                @click="exportAdhesions(true)"
              >
                <v-icon
                  size="18"
                  class="me-3"
                >
                  {{ mdiFileClockOutline }}
                </v-icon>
                <span>Exporter avant le 01/09/2022</span>
              </v-btn>
              <v-btn
                color="primary"
                class="pr-3"
                :loading="downloading.excel"
                :disabled="downloading.excelOld"
                @click="exportAdhesions()"
              >
                <v-icon
                  size="18"
                  class="me-3"
                >
                  {{ mdiFileExcelOutline }}
                </v-icon>
                <span>Exporter depuis le 01/09/2022</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        id="liste-adherents"
        :headers="tableColumnHeaders"
        :items="adherentsListData"
        item-key="id"
        :options.sync="options"
        :must-sort="true"
        :page.sync="pagination.current"
        :items-per-page="pagination.itemsPerPage"
        :page-count="pagination.pageCount"
        hide-default-footer
        class="table-rounded"
        :loading="loading"
        locale="fr-FR"
        no-data-text="Aucun résulat"
        loading-text="Chargement des données..."
        :server-items-length="pagination.pageCount"
        @update:pagination="updatePagination"
        @update:options="updateOptions"
      >
        <!-- filters -->
        <template v-slot:body.prepend>
          <tr class="filter-row">
            <td>
              <!-- :append-icon="mdiMagnify" -->
              <v-text-field
                id="id"
                :value="search.id"
                class="filter"
                label="ID..."
                single-line
                hide-details
                dense
                outlined
                clearable
                @blur="searchFilter"
                @click:clear="clearSearch('id')"
                @keyup.enter="searchFilter"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                id="name"
                :value="search.name"
                class="filter"
                label="Nom..."
                single-line
                hide-details
                dense
                outlined
                clearable
                @blur="searchFilter"
                @click:clear="clearSearch('name')"
                @keyup.enter="searchFilter"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                id="email"
                :value="search.email"
                class="filter"
                label="E-mail..."
                single-line
                hide-details
                dense
                outlined
                clearable
                @blur="searchFilter"
                @click:clear="clearSearch('email')"
                @keyup.enter="searchFilter"
              ></v-text-field>
            </td>
            <td>
              <v-menu
                ref="dateMenuRef"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateInput"
                    class="filter"
                    label="Mois d'inscription..."
                    single-line
                    hide-details
                    dense
                    outlined
                    clearable
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearSearch('created_at')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  type="month"
                  locale="fr-fr"
                  no-title
                  scrollable
                  @change="dateFilter(date)"
                >
                </v-date-picker>
              </v-menu>
            </td>
            <td>
              <v-select
                id="date_assignation"
                :value="search.date_assignation"
                class="filter"
                :items="dateAssignationList"
                item-text="label"
                item-value="date"
                label="Date assignation..."
                hide-details
                dense
                outlined
                clearable
                flat
                solo
                @click:clear="clearSearch('date_assignation')"
                @input="searchFilter"
              ></v-select>
            </td>
            <td>
              <v-select
                id="bon_id"
                :value="search.bon_id"
                class="filter"
                :items="cgpList"
                item-text="name"
                item-value="id"
                label="CGP..."
                hide-details
                dense
                outlined
                clearable
                flat
                solo
                @click:clear="clearSearch('bon_id')"
                @input="searchFilter"
              ></v-select>
            </td>
            <td>
              <v-select
                id="insurer"
                :value="search.insurer"
                class="filter"
                :items="insurersList"
                item-text="name"
                item-value="id"
                label="Assureurs..."
                hide-details
                dense
                outlined
                clearable
                flat
                solo
                @click:clear="clearSearch('insurer')"
                @input="searchFilter"
              ></v-select>
            </td><td colspan="2">
              <v-btn
                outlined
                block
                color="secondary"
                class="btn-reset"
                @click="ResetSearch"
              >
                <v-icon left>
                  {{ mdiRestore }}
                </v-icon>
                Réinitialiser tous les filtres
              </v-btn>
            </td>
            <td colspan="3">
              <v-btn
                outlined
                block
                color="secondary"
                class="btn-reset"
                :loading="downloading.filtered"
                @click="exportWithFilters"
              >
                <v-icon left>
                  {{ mdiMicrosoftExcel }}
                </v-icon>
                Exporter
              </v-btn>
            </td>
          </tr>
        </template>
        <!-- action -->
        <template #[`item.actions`]="{ item, index }">
          <v-btn
            icon
            title="Modifier l'adhérent"
            :to="{ name: 'modif-adherents', params: { id: item.adhesion_id } }"
          >
            <v-icon>
              {{ mdiAccountEdit }}
            </v-icon>
          </v-btn>
          <!-- <v-btn
            icon
            title="Documents adhérent"
            :loading="item.fileDownloading"
            @click="downloadFiles(item)"
          >
            <v-icon>
              {{ mdiFileMultiple }}
            </v-icon>
          </v-btn> -->
          <v-btn
            icon
            title="Fiche PDF"
            :loading="item.pdfDownloading"
            @click="pdf(item)"
          >
            <v-icon>
              {{ mdiFilePdfBox }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
            title="Supprimer l'adhérent"
            @click="remove(item, index)"
          >
            <v-icon>
              {{ mdiDelete }}
            </v-icon>
          </v-btn>
        </template>

        <!-- name -->
        <template #[`item.fullName`]="{item}">
          <div class="d-flex align-center name">
            <v-speed-dial
              v-model="item.validation_menu"
              direction="right"
              transition="slide-x-transition"
            >
              <template v-slot:activator>
                <v-btn
                  :color="colors[item.validation]"
                  x-small
                  fab
                  :loading="item.validation_loading"
                >
                  <v-icon v-if="item.validation === 1">
                    {{ mdiHelpCircleOutline }}
                  </v-icon>
                  <v-icon v-if="item.validation === 2">
                    {{ mdiProgressPencil }}
                  </v-icon>
                  <v-icon v-if="item.validation === 3">
                    {{ mdiCheckCircleOutline }}
                  </v-icon>
                  <v-icon v-if="item.validation === 4">
                    {{ mdiAlertCircleOutline }}
                  </v-icon>
                  <v-icon v-if="(item.validation === 5)">
                    {{ mdiCheckDecagramOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <v-btn
                v-if="item.validation !== 1"
                fab
                dark
                x-small
                color="secondary"
                @click="setValidation(item, 1)"
              >
                <v-icon>{{ mdiHelpCircleOutline }}</v-icon>
              </v-btn>
              <v-btn
                v-if="item.validation !== 2"
                fab
                dark
                x-small
                color="warning"
                @click="setValidation(item, 2)"
              >
                <v-icon>{{ mdiProgressPencil }}</v-icon>
              </v-btn>
              <v-btn
                v-if="item.validation !== 3"
                fab
                dark
                x-small
                color="info"
                @click="setValidation(item, 3)"
              >
                <v-icon>{{ mdiCheckCircleOutline }}</v-icon>
              </v-btn>
              <v-btn
                v-if="(item.validation !== 5)"
                fab
                dark
                x-small
                color="success"
                @click="setValidation(item, 5)"
              >
                <v-icon>{{ mdiCheckDecagramOutline }}</v-icon>
              </v-btn>
              <v-btn
                v-if="item.validation !== 4"
                fab
                dark
                x-small
                color="error"
                @click="setValidation(item, 4)"
              >
                <v-icon>{{ mdiAlertCircleOutline }}</v-icon>
              </v-btn>
            </v-speed-dial>
            <div class="d-flex flex-column ms-3 max-w-full pr-5">
              <span class="d-block text--primary font-weight-semibold text-truncate text-capitalize">{{ item.fullName }}</span>
            </div>
          </div>
        </template>

        <!-- email -->
        <template #[`item.email`]="{item}">
          <div class="d-flex align-center email">
            <span class="text-truncate">{{ item.email }}</span>
          </div>
        </template>

        <!-- insurers -->
        <template #[`item.insurer`]="{item}">
          <div class="d-flex insurer">
            <span class="text-truncate w-full">{{ item.insurer }}</span>
          </div>
        </template>

        <!-- status -->
        <!-- <template #[`item.statut`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="statut[item.type].color"
              :class="`v-avatar-light-bg ${statut[item.type].color}--text me-3`"
            >
              <v-icon
                size="20"
                :color="statut[item.type].color"
              >
                {{ statut[item.type].icon }}
              </v-icon>
            </v-avatar>
            {{ item.statut }}
          </div>
        </template> -->

        <!-- ldm -->
        <template #[`item.ldm`]="{item}">
          <div class="d-flex align-center justify-center">
            <v-icon
              size="20"
              :color="ldmColor[item.ldm]"
            >
              {{ ldmIcon[item.ldm] }}
            </v-icon>
          </div>
        </template>

        <!-- prejudice -->
        <template #[`item.prejudice`]="{item}">
          <div class="prejudice">
            {{ item.prejudice }}
          </div>
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            md="3"
            cols="12"
          >
            <v-select
              :value="pagination.itemsPerPage"
              :items="nbItemsPerPage"
              item-text="label"
              item-value="nb"
              label="Résultats par page"
              solo
              @change="updateNbItems($event)"
            ></v-select>
          </v-col>

          <v-col
            md="9"
            cols="12"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="pagination.current"
              :total-visible="12"
              :length="pagination.pageCount"
              @input="updatePagination"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbarVisible"
      :timeout="3000"
      :color="snackbarColor"
      top
    >
      {{ snackbarMsg }}
    </v-snackbar>
    <v-dialog
      v-model="showDeleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Supprimer l'adhésion de {{ dialogAdherent }} ?
        </v-card-title>
        <v-card-text>Ceci supprimera l'ensemble des données de l'adhérent.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            dark
            outlined
            :disabled="deleteLoading"
            @click="showDeleteDialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :loading="deleteLoading"
            dark
            @click="deleteAdhesion()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint-disable object-curly-newline */
import axios from '@axios'
import { mdiAccountEdit, mdiAlertCircleOutline, mdiCancel, mdiCheckBold, mdiCheckCircleOutline, mdiCheckDecagramOutline, mdiDelete, mdiFileClockOutline, mdiFileExcelOutline, mdiFileMultiple, mdiFilePdfBox, mdiHelpCircleOutline, mdiMagnify, mdiMicrosoftExcel, mdiProgressPencil, mdiRestore } from '@mdi/js'
import themeConfig from '@themeConfig'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import goTo from 'vuetify/lib/services/goto'
import { easeInOutCubic } from 'vuetify/lib/services/goto/easing-patterns'
import store from '@/store/adherentsFilters'
import { currencyFormat, dateFormat } from '../../../@core/utils'
import fileDownload from '../../../@core/utils/filesDownload'
import insurers from '../../../data/insurers'
/* eslint-enable object-curly-newline */

export default {
  setup() {
    const { role } = JSON.parse(sessionStorage.getItem('userData'))

    const insurersList = [{
      id: 'aucun',
      name: 'Aucun',
    },
    ...Object.keys(insurers).map(i => ({
      id: i,
      name: insurers[i],
    }))]

    // const fileDownloading = false
    let pdfDownloading = false
    let idToDelete = null
    let indexToDelete = null

    // const statusColor = {
    //   /* eslint-disable key-spacing */
    //   Current: 'primary',
    //   Professional: 'success',
    //   Rejected: 'error',
    //   Resigned: 'warning',
    //   Applied: 'info',
    //   /* eslint-enable key-spacing */
    // }
    const month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
    const cgpList = ref([
      {
        id: 0,
        name: 'Aucun',
      },
    ])
    const dateAssignationList = [
      {
        label: 'Aucune',
        date: 'none',
      },
      {
        label: '19/12/2023',
        date: '2023-12-19',
      },
      {
        label: '28/05/2024',
        date: '2024-05-28',
      },
    ]
    const formatDate = d => {
      d.created_at.split('-')

      return `${month[+d[1] - 1]} ${d[0]}`
    }
    const search = computed(() => store.state.adherentsFilters.search)
    const dateInput = computed(() => {
      if (search.value.created_at !== null) {
        return formatDate(search.value.created_at)
      }

      return null
    })
    const validation = computed({
      get() {
        return search.value.validation
      },
      set(value) {
        store.commit('adherentsFilters/SET_SEARCH', { prop: 'validation', value })
      },
    })

    const options = ref({
      sortBy: store.state.adherentsFilters.options.sortBy,
      sortDesc: store.state.adherentsFilters.options.sortDesc,
    })

    const date = ref(search.value.created_at || new Date().toISOString().substr(0, 7))
    const snackbarMsg = ref('')
    const snackbarVisible = ref(false)
    const snackbarColor = ref(null)
    const showDeleteDialog = ref(false)
    const deleteLoading = ref(false)
    const dialogAdherent = ref(null)
    const dateMenu = ref(false)
    const dateMenuRef = ref(null)

    const downloading = ref({
      excel: false,
      excelOld: false,
      filtered: false,
    })

    const cgp = ref({})

    const nbItemsPerPage = ref([
      { nb: 5, label: '5 résultats par page' },
      { nb: 10, label: '10 résultats par page' },
      { nb: 15, label: '15 résultats par page' },
      { nb: 25, label: '25 résultats par page' },
      { nb: 50, label: '50 résultats par page' },
    ])

    // const statut = {
    //   particulier: { icon: mdiAccountOutline, color: 'info' },
    //   societe: { icon: mdiDomain, color: 'success' },
    // }

    const ldmColor = [
      'secondary',
      'success',
    ]

    const ldmIcon = [
      mdiCancel,
      mdiCheckBold,
    ]

    const colors = ['primary', 'secondary', 'warning', 'info', 'error', 'success']

    const adherentsListData = ref([])
    const loading = ref(true)

    const tableColumnHeaders = [
      {
        text: 'ID', value: 'adhesion_id', sortable: true, width: 70,
      },
      { text: 'ADHÉRENT', value: 'fullName', sortable: false },
      { text: 'E-MAIL', value: 'email', sortable: false },
      { text: 'INSCRIPTION', value: 'created_at', align: 'center' },
      {
        text: 'ASSIGNATION', value: 'date_assignation', align: 'center', sortable: false,
      },
      {
        text: 'CGP', value: 'bon_id', align: 'center', sortable: false,
      },
      {
        text: 'ASSUREUR', value: 'insurer', align: 'left', sortable: false,
      },
      {
        text: 'COTIS.',
        value: 'cotisation',
        align: 'center',
        sortable: false,
        width: 120,
      },

      // {
      //   text: 'STATUT',
      //   value: 'statut',
      //   align: 'center',
      //   sortable: false,
      // },
      {
        text: 'LDM',
        value: 'ldm',
        align: 'center',
        sortable: false,
        width: 80,
      },
      {
        text: 'PRÉJUDICE', value: 'prejudice', align: 'right', sortable: false,
      },
      {
        text: 'ACTIONS', value: 'actions', align: 'center', width: 150, sortable: false,
      },
    ]

    const pagination = ref({
      itemsPerPage: store.state.adherentsFilters.per_page,
      current: store.state.adherentsFilters.page,
      total: 0,
      pageCount: 0,
    })

    const setFilters = () => {
      let params = ''
      const { sortDesc } = options.value

      if (sortDesc.length === 1) {
        params += `order=${sortDesc[0] ? 'desc' : 'asc'}`
      } else {
        params += 'order=desc'
      }

      if (search.value.id !== null) {
        params += `&id=${encodeURI(search.value.id)}`
      }

      if (search.value.name !== null) {
        params += `&name=${encodeURI(search.value.name)}`
      }

      if (search.value.email !== null) {
        params += `&email=${encodeURI(search.value.email)}`
      }

      if (search.value.bon_id !== null) {
        const bonId = search.value.bon_id === 0 ? null : search.value.bon_id
        params += `&bon_id=${bonId}`
      }

      if (search.value.date_assignation !== null) {
        params += `&date_assignation=${search.value.date_assignation}`
      }

      if (search.value.insurer !== null) {
        const insurer = search.value.insurer === 'aucun' ? null : search.value.insurer
        params += `&insurer=${insurer}`
      }

      if (search.value.created_at !== null) {
        params += `&created_at=${encodeURI(search.value.created_at)}`
      }

      if (search.value.validation !== undefined && search.value.validation !== null) {
        params += `&validation=${search.value.validation}`
      }

      return params
    }

    const getAdherents = async (resetPagination = false) => {
      if (resetPagination) {
        pagination.value.current = 1
        store.commit('adherentsFilters/SET_PAGE', 1)
      }
      const params = `&${setFilters()}`
      loading.value = true

      await axios
        .get(`adhesions?page=${pagination.value.current}&rowsPerPage=${pagination.value.itemsPerPage}${params}`)
        .then(response => {
          adherentsListData.value = []

          // const statutAdherent = ''
          let fullName = ''
          let initiales = ''
          if (response.data.data.length > 0) {
            response.data.data.forEach(adhesion => {
              if (adhesion.adherent.statut === 'societe') {
                const societe = adhesion.adherent.societe !== null ? adhesion.adherent.societe : '?'
                fullName = societe.toLowerCase()

                // statutAdherent = 'PM'
                initiales = societe.slice(0, 2).toUpperCase()
              } else {
                const nom = adhesion.adherent.nom !== null ? adhesion.adherent.nom : '?'
                const prenom = adhesion.adherent.prenom !== null ? adhesion.adherent.prenom : '?'
                fullName = `${nom} ${prenom}`.toLowerCase()

                // statutAdherent = 'PP'
                initiales = nom.slice(0, 1).toUpperCase() + prenom.slice(0, 1).toUpperCase()
              }
              adherentsListData.value.push({
                id: adhesion.adherent.id,
                adhesion_id: adhesion.id,
                hash_id: adhesion.hash_id,
                fullName,
                initiales,
                email: adhesion.adherent.email,
                cotisation: currencyFormat(adhesion.cotisation),
                bon_id: adhesion.bon_id === null ? '-' : cgp.value[adhesion.bon_id],
                insurer: adhesion.insurer === null ? '-' : insurers[adhesion.insurer],

                // statut: statutAdherent,
                ldm: adhesion.ldm,
                type: adhesion.adherent.statut,
                prejudice: typeof adhesion.prejudice === 'number' ? currencyFormat(adhesion.prejudice) : '-',
                created_at: dateFormat(adhesion.created_at),
                date_assignation: adhesion.date_assignation === null ? '-' : dateFormat(adhesion.date_assignation),
                fileDownloading: false,
                pdfDownloading: false,
                validation: adhesion.validation,
                validation_menu: false,
                validation_loading: false,
              })
            })
          }

          // pagination.value.itemsPerPage = +response.data.per_page
          pagination.value.pageCount = +response.data.last_page

          // pagination.value.current = response.data.current_page
          pagination.value.total = response.data.total
          loading.value = false
        })
        .catch(e => {
          loading.value = false
          throw e
        })
    }

    const updateNbItems = e => {
      store.commit('adherentsFilters/SET_PER_PAGE', e)
      pagination.value.itemsPerPage = e
      pagination.value.current = 1
      getAdherents()
    }

    const searchFilter = e => {
      if (e === null) {
        return false
      }
      let prop
      let value
      if (dateAssignationList.map(d => d.date).includes(e)) {
        prop = 'date_assignation'
        value = e
      } else if (typeof e === 'number') {
        prop = 'bon_id'
        value = e
      } else if (typeof e === 'string') {
        prop = 'insurer'
        value = e
      } else {
        prop = e.target.id
        value = e.target.value
      }
      if (search.value[prop] === value || (search.value[prop] === null && value === '')) {
        return false
      }
      store.commit('adherentsFilters/SET_SEARCH', { prop, value })
      getAdherents(true)

      return true
    }

    const dateFilter = d => {
      store.commit('adherentsFilters/SET_SEARCH', { prop: 'created_at', value: d })
      dateMenu.value = false
      dateMenuRef.value.save(d)
      getAdherents(true)
    }

    const clearSearch = target => {
      if (search.value[target] === null) {
        return false
      }

      store.commit('adherentsFilters/SET_SEARCH', { prop: target, value: null })
      getAdherents(true)

      return true
    }

    const ResetSearch = () => {
      store.commit('adherentsFilters/RESET_SEARCH')
      getAdherents(true)
    }

    // const downloadFiles = async item => {
    //   if (fileDownloading) {
    //     return false
    //   }
    //   item.fileDownloading = true
    //   fileDownloading = true
    //   snackbarVisible.value = false
    //   snackbarMsg.value = ''
    //   const url = `${themeConfig.app.baseURL}api/documents/get/${item.hash_id}`
    //   const filename = `${item.fullName.replace(' ', '-')}.zip`
    //   try {
    //     await fileDownload(url, filename)
    //     item.fileDownloading = false
    //     fileDownloading = false
    //   } catch (e) {
    //     item.fileDownloading = false
    //     fileDownloading = false
    //     snackbarVisible.value = true
    //     snackbarColor.value = 'error'
    //     snackbarMsg.value = e
    //     throw e
    //   }

    //   return true
    // }

    const updatePagination = page => {
      store.commit('adherentsFilters/SET_PAGE', page)
      goTo('#liste-adherents', {
        duration: 500,
        easing: easeInOutCubic,
      })
    }

    const remove = (item, index) => {
      idToDelete = item.adhesion_id
      indexToDelete = index
      dialogAdherent.value = item.fullName
      showDeleteDialog.value = true
    }

    const deleteAdhesion = async () => {
      deleteLoading.value = true
      await axios.get(`adhesions/delete/${idToDelete}`).then(() => {
        adherentsListData.value.splice(indexToDelete, 1)
        deleteLoading.value = false
        showDeleteDialog.value = false
        snackbarColor.value = 'success'
        snackbarMsg.value = 'Adhérent supprimé !'
        snackbarVisible.value = true
      }).catch(e => {
        snackbarColor.value = 'error'
        snackbarMsg.value = 'Une erreur serveur est survenue.'
        snackbarVisible.value = true
        deleteLoading.value = false
        showDeleteDialog.value = false
        console.error(e)
      })
    }

    const pdf = async item => {
      if (!pdfDownloading) {
        item.pdfDownloading = true
        pdfDownloading = true
        snackbarVisible.value = false
        snackbarMsg.value = ''
        const filename = `${item.fullName.replace(' ', '-')}.pdf`
        try {
          await fileDownload(`${themeConfig.app.baseURL}api/adhesions/pdf/${item.adhesion_id}`, filename)
          item.pdfDownloading = false
          pdfDownloading = false
        } catch (e) {
          item.pdfDownloading = false
          pdfDownloading = false
          snackbarVisible.value = true
          snackbarColor.value = 'error'
          snackbarMsg.value = e
          throw e
        }
      }
    }

    const exportAdhesions = async (old = false) => {
      if (!downloading.value.excel || downloading.value.excelOld) {
        if (old) {
          downloading.value.excelOld = true
        } else {
          downloading.value.excel = true
        }
        snackbarVisible.value = false
        snackbarMsg.value = ''
        let params = ''
        if (old) {
          params = '?old=1'
        }
        const today = new Date()
        const filename = `adhesions_${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}--${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}.xlsx`
        try {
          await fileDownload(`${themeConfig.app.baseURL}api/adhesions-all${params}`, filename)
        } catch (e) {
          snackbarVisible.value = true
          snackbarColor.value = 'error'
          snackbarMsg.value = e
          throw e
        } finally {
          downloading.value.excel = false
          downloading.value.excelOld = false
        }
      }
    }

    const exportWithFilters = async () => {
      downloading.value.filtered = true
      snackbarVisible.value = false

      const params = setFilters()

      const ts = Date.now()
      const filename = `adhesions_collectif-h2o_${ts}.xlsx`

      try {
        await fileDownload(`${themeConfig.app.baseURL}api/adhesions-filtered?${params}`, filename)
      } catch (e) {
        snackbarVisible.value = true
        snackbarColor.value = 'error'
        snackbarMsg.value = e
        throw e
      } finally {
        downloading.value.filtered = false
      }
    }

    const setValidation = async (item, val) => {
      item.validation_loading = true

      await axios.post(`${themeConfig.app.baseURL}api/adhesions/update/${item.adhesion_id}/validation/${val}`)
        .then(response => {
          item.validation = response.data.validation
        })
        .catch(e => {
          snackbarVisible.value = true
          snackbarColor.value = 'error'
          snackbarMsg.value = e
        })
        .finally(() => {
          item.validation_loading = false
        })
    }

    const updateOptions = vals => {
      store.commit('adherentsFilters/SET_OPTIONS', { prop: 'sortBy', value: vals.sortBy })
      store.commit('adherentsFilters/SET_OPTIONS', { prop: 'sortDesc', value: vals.sortDesc })
    }

    watch(
      options,
      () => {
        getAdherents()
      },
    )

    watch(
      validation,
      () => {
        getAdherents()
      },
    )

    onMounted(async () => {
      await axios.get('conseillers/list-by-bon-id').then(response => {
        cgp.value = response.data
        Object.keys(response.data).forEach(key => {
          cgpList.value.push({
            id: +key,
            name: response.data[key],
          })
        })
      })
    })

    return {
      role,
      options,
      search,
      updateNbItems,
      getAdherents,
      adherentsListData,

      // statusColor,
      // statut,
      tableColumnHeaders,
      colors,
      loading,
      pagination,
      mdiMagnify,
      mdiFileMultiple,
      mdiDelete,
      mdiCheckDecagramOutline,
      mdiFilePdfBox,
      mdiFileExcelOutline,
      mdiProgressPencil,
      mdiAlertCircleOutline,
      mdiCheckCircleOutline,
      mdiMicrosoftExcel,
      mdiHelpCircleOutline,
      mdiFileClockOutline,
      mdiAccountEdit,
      mdiRestore,
      mdiCancel,
      mdiCheckBold,
      nbItemsPerPage,

      // downloadFiles,
      snackbarMsg,
      snackbarVisible,
      snackbarColor,
      searchFilter,
      clearSearch,
      updatePagination,
      showDeleteDialog,
      deleteLoading,
      downloading,
      remove,
      deleteAdhesion,
      dialogAdherent,
      pdf,
      cgp,
      cgpList,
      dateAssignationList,
      exportAdhesions,
      exportWithFilters,
      validation,
      setValidation,
      dateMenu,
      dateMenuRef,
      date,
      dateInput,
      dateFilter,
      ResetSearch,
      updateOptions,
      ldmIcon,
      ldmColor,
      insurers,
      insurersList,
    }
  },
}
</script>
<style scoped>
  .modal-title, .prejudice {
    white-space: nowrap;
  }

  .btn-reset {
    min-height: 40px;
    font-size: 12px;
  }

  .name {
    max-width: 160px;
  }

  .email {
    max-width: 120px;
  }

  .insurer {
    max-width: 130px;
  }

  .max-w-full {
    max-width: 100%;
  }

</style>
