const insurers = {
  wealins: 'Wealins',
  'la-baloise': 'La Baloise',
  'la-mondiale-europartner': 'La Mondiale Europartner',
  'la-mondiale-partenaire': 'La Mondiale Partenaire',
  'swiss-life': 'Swiss Life',
  'afi-esca': 'AFI ESCA',
  'vitis-life': 'Vitis Life',
}

export default insurers
